import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	useAxios,
	Container,
	LoadingBox,
	TextButton,
	SpecimenInfo,
	TRFComponents,
	DataComponents,
	ContentSection,
	ReportVersions,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ActionLog,
	ClinicData,
	Pagination,
	NonClinicData,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import Notes from '../../../components/notes';
import ActionLogTable from '../../../components/tables/action-logs-table';

import { NavigateToUpdatePage } from '../update-data-components/navigate-to-update';

/* eslint-disable-next-line */
export interface ViewSampleProps {}

export default function ViewSample(props: ViewSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { dataId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [data, setData] = useState<ClinicData | NonClinicData | undefined>(
		undefined
	);

	const [sampleHistoryPagination, setSampleHistoryPagination] =
		useState<Pagination>(initialPaginationValues);
	const [sampleHistory, setSampleHistory] = useState<ActionLog[] | undefined>(
		undefined
	);

	const [
		requisitionFormHistoryPagination,
		setRequisitionFormHistoryPagination,
	] = useState<Pagination>(initialPaginationValues);
	const [requisitionFormHistory, setRequisitionFormHistory] = useState<
		ActionLog[] | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/client-services/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, refresh]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setSampleHistory(undefined);

			try {
				const [
					{
						data: {
							data: {
								data: data1,
								total_entries: total_entries1,
							},
						},
					},
					{
						data: {
							data: {
								data: data2,
								total_entries: total_entries2,
							},
						},
					},
				] = await Promise.all([
					await (
						await axios
					).get(
						`/api/client-services/data/${dataId}/action-history?type=sample&page_number=${sampleHistoryPagination.page_number}&entries_per_page=${sampleHistoryPagination.entries_per_page}`
					),
					await (
						await axios
					).get(
						`/api/client-services/data/${dataId}/action-history?type=requisition_form&page_number=${requisitionFormHistoryPagination.page_number}&entries_per_page=${requisitionFormHistoryPagination.entries_per_page}`
					),
				]);

				if (!unmounted) {
					const parsedData1 = JSON.parse(data1);

					setSampleHistory(parsedData1);
					setSampleHistoryPagination((prev) => ({
						...prev,
						total_entries: total_entries1,
					}));

					const parsedData2 = JSON.parse(data2);

					setRequisitionFormHistory(parsedData2);
					setRequisitionFormHistoryPagination((prev) => ({
						...prev,
						total_entries: total_entries2,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		dataId,
		refresh,
		sampleHistoryPagination.page_number,
		sampleHistoryPagination.entries_per_page,
		requisitionFormHistoryPagination.page_number,
		requisitionFormHistoryPagination.entries_per_page,
	]);

	const handleDownloadReport = async () => {
		if (!axios || !data || !data.sample.report.aws_object_key) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data: report_data },
			} = await (
				await axios
			).get(
				`/api/client-services/report?aws_object_key=${data.sample.report.aws_object_key}`
			);

			toast.dismiss();

			window.open(report_data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Container>
			<ContentWrapper Icon="FingerPrintIcon" heading="Sample Details">
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<SpecimenInfo data={data} />

						<ContentSectionDivider />

						{data.type === 'clinic' && (
							<Fragment>
								<TRFComponents.PatientInformation data={data} />
								<ContentSectionDivider />
							</Fragment>
						)}

						<DataComponents.ProviderInformation data={data} />

						<ContentSectionDivider />

						<DataComponents.OrganizationInformation data={data} />

						<ContentSectionDivider />

						<ContentSection heading="Requisition form Info">
							<DescriptionList>
								<DescriptionItem term="Requisition Form">
									<TextButton
										text="View"
										color="blue"
										type="button"
										onClick={() => {
											window.open(
												`client-services/view/trfs/${data._id.$oid}`,
												'_blank'
											);
										}}
									/>
								</DescriptionItem>
							</DescriptionList>
						</ContentSection>

						{data.sample.batch && (
							<Fragment>
								<ContentSectionDivider />

								<ContentSection heading="Batch details">
									<DescriptionList>
										<DescriptionItem
											term="Name"
											details={data.sample.batch.name}
										/>
										<DescriptionItem
											term="QC Passed?"
											details={
												data.sample.batch.qc.passed ===
												null
													? null
													: data.sample.batch.qc
															.passed
													? 'Acceptable'
													: 'Failed'
											}
										/>
									</DescriptionList>
								</ContentSection>
							</Fragment>
						)}

						<ContentSectionDivider />

						<ContentSection heading="Report details">
							<DescriptionList>
								<DescriptionItem
									term="Verified?"
									details={
										data.sample.report.verified.value
											? 'Yes'
											: 'No'
									}
								/>
								<DescriptionItem
									term="Report PDF"
									customColSpan="sm:col-span-3"
								>
									{data.sample.report.aws_object_key ? (
										<TextButton
											color="blue"
											type="button"
											text="Download"
											onClick={handleDownloadReport}
										/>
									) : (
										'Not available'
									)}
								</DescriptionItem>

								<DescriptionItem
									term="Update Data"
									customColSpan="sm:col-span-3"
								>
									{data.sample.status === 'verified' ? (
										<NavigateToUpdatePage
											assay={data.sample.assay}
											type={data.type}
											version={
												data.requisition_form
													? data.requisition_form
															.metadata.version
													: ''
											}
										/>
									) : (
										'Not available'
									)}
								</DescriptionItem>

								<ReportVersions data={data} />
							</DescriptionList>
						</ContentSection>

						<ContentSectionDivider />

						{sampleHistory && (
							<Fragment>
								<ContentSection
									heading="Sample history"
									description="These are actions performed on the sample as it's being processed through the facility."
								>
									<ActionLogTable
										type="sample"
										pagination={{
											pagination: sampleHistoryPagination,
											setPagination:
												setSampleHistoryPagination,
										}}
										data={sampleHistory}
									/>
								</ContentSection>

								<ContentSectionDivider />
							</Fragment>
						)}

						{requisitionFormHistory && (
							<Fragment>
								<ContentSection
									heading="Requisition form history"
									description="These are the changes made to the requisition form."
								>
									<ActionLogTable
										type="requisition_form"
										pagination={{
											pagination:
												requisitionFormHistoryPagination,
											setPagination:
												setRequisitionFormHistoryPagination,
										}}
										data={requisitionFormHistory}
									/>
								</ContentSection>

								<ContentSectionDivider />
							</Fragment>
						)}

						<ContentSection heading="Miscellaneous">
							<DescriptionList>
								<DescriptionItem
									term="Notes"
									customColSpan="sm:col-span-3"
								>
									<Notes
										id={dataId}
										collection="data"
										setRefresh={setRefresh}
										data={data.notes}
										receiveDepartment="client_services"
									/>
								</DescriptionItem>
							</DescriptionList>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
