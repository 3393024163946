/* eslint-disable @typescript-eslint/no-empty-function */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	useId,
	Dispatch,
	Fragment,
	useState,
	useContext,
	SetStateAction,
} from 'react';

import {
	Alert,
	Modal,
	Button,
	useAxios,
	FormFooter,
	LoadingBox,
	TextButton,
	ContentSection,
	DescriptionItem,
	DescriptionList,
	ButtonWithDropdown,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	IconName,
	BAR_ACTIONS,
	NonClinicData,
	getLabelFromValue,
	getSampleStatuses,
} from '@pangea-lis-apps/utils';

import { ProcessSampleContext } from './process-sample-layout';
import RejectSampleModal from '../../../../../../components/modals/reject-sample-modal';
import RemoveSampleFromRackModal from '../../../../../../components/modals/remove-sample-from-rack-modal';

interface ActionItem {
	name: string;
	Icon: IconName;
	handleClick: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccessionVerifySampleProps {}

export default function AccessionVerifySample(
	props: AccessionVerifySampleProps
) {
	const navigate = useNavigate();
	const { assay, dataId } = useParams();

	const { user } = useAuth0();
	const isAccessioningAdmin =
		user && user['custom_claims/roles'].includes('BITSS_accessioner-admin');

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	const [modalVisible, setModalVisible] = useState<{
		[key: string]: boolean;
	}>({
		reject: false,
		approve: false,
	});
	const [showNotificationModal, setShowNotificationModal] = useState(false);

	const { data, canUpdateSampleInfo } = useContext(ProcessSampleContext);

	return data ? (
		<Fragment>
			<RejectSampleModal
				data={data}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<ApproveSampleModal
				data={data}
				setNotificationModalVisible={setShowNotificationModal}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<RemoveSampleFromRackModal
				data={data}
				visible={{
					visible: showNotificationModal,
					setVisible: setShowNotificationModal,
				}}
			/>
			<ContentSection className="bg-gray-50 border border-gray-200 py-8 rounded-lg">
				<div className="flex items-center justify-between">
					<TextButton
						color="gray"
						type="button"
						onClick={() =>
							navigate(
								`/accessioner/accession/sample/${assay}/data/${dataId}/non-clinic-sample/verify-priority${redirectPathQuery}`
							)
						}
						text={<span>&#8592; Back</span>}
					/>
					{(() => {
						if (canUpdateSampleInfo || isAccessioningAdmin) {
							const actions: { [key: string]: ActionItem } = {
								FLAG: {
									name: 'Flag',
									Icon: 'FlagIcon',
									handleClick: () =>
										navigate(
											`/accessioner/accession/sample/${assay}/data/${dataId}/non-clinic-sample/bar/flag${redirectPathQuery}`
										),
								},
								REJECT: {
									name: 'Reject',
									Icon: 'XCircleIcon',
									handleClick: () =>
										setModalVisible((prevValue) => ({
											...prevValue,
											reject: true,
										})),
								},
								APPROVE: {
									name: 'Approve',
									Icon: 'CheckIcon',
									handleClick: () =>
										setModalVisible((prevValue) => ({
											...prevValue,
											approve: true,
										})),
								},
								REQUEST_TO_REVIEW: {
									Icon: 'DocumentCheckIcon',
									name: 'Request to review',
									handleClick: () =>
										navigate(
											`/accessioner/accession/sample/${assay}/data/${dataId}/non-clinic-sample/bar/review${redirectPathQuery}`
										),
								},
							};

							const items: ActionItem[] = [actions['FLAG']];

							if (data.sample.status !== 'reject') {
								items.push(actions['REJECT']);
							}

							const accessioning_completed =
								data.metadata.accessioning.completed.value;

							if (
								(![
									'pending',
									'pending_verification',
									'verified',
								].includes(data.sample.status) &&
									accessioning_completed) ||
								(data.sample.status === 'reviewed' &&
									!data.sample.accessioning_approval.reviewed
										.reject)
							) {
								items.unshift(actions['APPROVE']);
							}

							// Always show request to review
							items.push(actions['REQUEST_TO_REVIEW']);

							return (
								<ButtonWithDropdown
									Icon={items[0].Icon}
									items={items.slice(1)}
									mainButtonText={items[0].name}
									mainClick={items[0].handleClick}
								/>
							);
						}

						return (
							<Button
								type="button"
								tier="tertiary"
								text="Continue"
								onClick={() => {
									if (redirectPath) {
										navigate(redirectPath);
										return;
									}

									navigate(
										`/accessioner/accession/sessions/${data.accessioning_session._id.$oid}/preprocess`
									);
								}}
							/>
						);
					})()}
				</div>
			</ContentSection>
		</Fragment>
	) : null;
}

interface ApproveSampleModalProps {
	data: NonClinicData | undefined;
	setNotificationModalVisible: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: { [key: string]: boolean };
		setVisible: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
	};
}

export function ApproveSampleModal(props: ApproveSampleModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { dataId } = useParams();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async () => {
		if (disabled || !props.data || !axios) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/sample/approve`, {
				user,
			});

			toast.dismiss();

			setDisabled(false);

			if (BAR_ACTIONS.includes(props.data.sample.status)) {
				props.setNotificationModalVisible(true);
				setVisible((prevValue) => ({
					...prevValue,
					approve: false,
				}));
				return;
			}

			navigate(
				redirectPath
					? redirectPath
					: `/accessioner/accession/sessions/${props.data.accessioning_session._id.$oid}/preprocess`
			);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Modal
			title="Approve sample"
			customWidth="max-w-md"
			visible={visible['approve']}
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					approve: false,
				}))
			}
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<Alert
						type="info"
						className="mb-4"
						heading="Confirm approval"
						description="Review the details and confirm approval."
					/>
					<DescriptionList>
						<DescriptionItem
							term="Status"
							details={getLabelFromValue(
								props.data.sample.status,
								getSampleStatuses()
							)}
						/>
						{['needs_review', 'reviewed'].includes(
							props.data.sample.status
						) && (
							<Fragment>
								<DescriptionItem
									term="Review decision"
									details={
										props.data.sample.accessioning_approval
											.reviewed.reject === null
											? null
											: props.data.sample
													.accessioning_approval
													.reviewed.reject
											? 'Rejected'
											: 'Approved'
									}
								/>
								<DescriptionItem
									term="Request to review reason"
									details={
										props.data.sample.accessioning_approval
											.needs_review.reason.value
									}
								/>
							</Fragment>
						)}
						{props.data.sample.status === 'flag' && (
							<Fragment>
								<DescriptionItem
									term="Flag Reason"
									details={
										props.data.sample.accessioning_approval
											.flag.reason.value
									}
								/>
								<DescriptionItem
									term="Well location"
									details={
										props.data.sample.accessioning_approval
											.flag.selected_well.rack_name &&
										props.data.sample.accessioning_approval
											.flag.selected_well.location &&
										`Rack ${
											props.data.sample
												.accessioning_approval.flag
												.selected_well.rack_name || '-'
										}, Well ${
											props.data.sample
												.accessioning_approval.flag
												.selected_well.location || '-'
										}`
									}
								/>
							</Fragment>
						)}
						<DescriptionItem
							term="Assay"
							details={getLabelFromValue(
								props.data.sample.assay,
								ASSAYS['assays']
							)}
							customColSpan="sm:col-span-3"
						/>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Specimen ID"
							details={props.data.sample.specimen_id}
						/>
						<div className="sm:col-span-1"></div>
						<DescriptionItem
							term="Provider"
							details={props.data.customer.first_name.concat(
								' ',
								props.data.customer.last_name
							)}
						/>
						<DescriptionItem
							term="Organization"
							details={props.data.organization.name}
						/>
					</DescriptionList>

					<FormFooter>
						<Button
							type="button"
							text="Approve"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
						/>
					</FormFooter>
				</Fragment>
			)}
		</Modal>
	);
}
