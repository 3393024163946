import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Alert,
	Modal,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	LoadingBox,
	SelectField,
	TextareaField,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	ClinicData,
	formatDate,
	NonClinicData,
	ORGANIZATIONS,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';

export const initialModalValue = {
	reject: false,
	verify: false,
	addNote: false,
	addComment: false,
	rerun: false,
	editResults: false,
};

interface ModalProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh?: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: { [key: string]: boolean };
		setVisible: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
	};
}

export interface ModalWithRefreshProps extends ModalProps {
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function AddNoteModal(props: ModalWithRefreshProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [note, setNote] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Adding note...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/cls/data/${props.data._id.$oid}/note`, {
				user,
				note,
			});

			toast.dismiss();

			setNote('');
			setDisabled(false);
			setVisible((prevValue) => ({
				...prevValue,
				addNote: false,
			}));

			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setNote(target.value);
	};

	return (
		<Modal
			title="Add note"
			customWidth="max-w-md"
			visible={visible['addNote']}
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					addNote: false,
				}))
			}
			description="Notes are for internal use only."
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Form>
					<FormGroup>
						<div className="sm:col-span-6">
							<TextareaField
								required
								name="note"
								value={note}
								label="Note"
								maxLength={220}
								handleInputChange={handleChange}
								placeholder="e.g., Cellular control failed but sample is positive."
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							type="button"
							text="Submit"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
						/>
					</FormFooter>
				</Form>
			)}
		</Modal>
	);
}

export function AddCommentModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const verified = props.data && props.data.sample.status === 'verified';

	const { user } = useAuth0();
	const {
		visible: { addComment: visible },
		setVisible,
	} = props.visible;

	const [comment, setComment] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).post(
				`/api/cls/data/${props.data._id.$oid}/sample/report/comment`,
				{ user, comment: comment.trim() }
			);

			toast.dismiss();

			handleClose();
			props.setRefresh && props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setComment(target.value);
	};

	const handleClose = () => {
		setComment('');
		setVisible((prevValue) => ({
			...prevValue,
			addComment: false,
		}));
		setDisabled(false);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			title="Add report comment"
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<div className="space-y-4">
					{verified && (
						<Alert
							type="warning"
							heading="New report will be generated"
							description="The report has already been verified. A new report will be generated with the provided comment."
						/>
					)}
					<Form>
						<FormGroup>
							<div className="sm:col-span-6">
								<TextareaField
									required
									name="comment"
									label="Comment"
									maxLength={220}
									value={comment}
									handleInputChange={handleChange}
									placeholder="e.g., Fails QC and waiting for rerun."
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								text="Add"
								type="button"
								tier="tertiary"
								Icon="PlusIcon"
								disabled={disabled}
								onClick={handleSubmit}
							/>
						</FormFooter>
					</Form>
				</div>
			)}
		</Modal>
	);
}

export function RerunModal(props: ModalWithRefreshProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState({
		_id: '',
		message: '',
		rerun_batch_remove: '',
		send_rerun_email_notification: '',
	});

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Marking as rerun...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/cls/data/samples/rerun`, {
				_id: props.data._id.$oid,
				message: formValues.message,
				rerun_batch_remove: formValues.rerun_batch_remove === 'true',
				send_rerun_email_notification:
					formValues.send_rerun_email_notification === 'true',
			});

			toast.dismiss();

			setVisible((prevValue) => ({
				...prevValue,
				rerun: false,
			}));
			setDisabled(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLTextAreaElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLTextAreaElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));
	};

	return (
		<Modal
			title="Rerun sample"
			customWidth="max-w-xl"
			visible={visible['rerun']}
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					rerun: false,
				}))
			}
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<DescriptionList
						heading="Sample Details"
						className="mb-4 bg-gray-50 rounded-lg border border-gray-200 p-4"
					>
						<DescriptionItem
							term="Assay"
							customColSpan="sm:col-span-3"
							details={getLabelFromValue(
								props.data.sample.assay,
								ASSAYS['assays']
							)}
						/>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Received Date"
							details={formatDate(
								props.data.sample.sample_received_date,
								true
							)}
						/>
						<DescriptionItem
							term="Priority Level"
							details={getLabelFromValue(
								props.data.sample.priority,
								ORGANIZATIONS.priorityLevels
							)}
						/>
						{'batch' in props.data.sample && (
							<DescriptionItem
								term="In batch?"
								details={
									props.data.sample.batch
										? props.data.sample.batch.name
										: null
								}
							/>
						)}
					</DescriptionList>

					<Form>
						<FormGroup>
							{'batch' in props.data.sample &&
								props.data.sample.batch && (
									<div className="sm:col-span-6">
										<SelectField
											required
											handleSelect={handleChange}
											options={FORMS.yes_no_options}
											name="rerun_batch_remove"
											label="Remove from batch?"
											value={
												formValues.rerun_batch_remove ||
												''
											}
										/>
									</div>
								)}
							<div className="sm:col-span-6">
								<SelectField
									required
									handleSelect={handleChange}
									options={FORMS.yes_no_options}
									name="send_rerun_email_notification"
									label="Send rerun email notification?"
									value={
										formValues.send_rerun_email_notification ||
										''
									}
								/>
							</div>
							<div className="sm:col-span-6">
								<TextareaField
									required
									name="message"
									label="Rerun reason"
									value={formValues.message}
									handleInputChange={handleChange}
									placeholder="Please provide the reason for the rerun."
									description={
										<span>
											If{' '}
											<span className="italic">
												Send rerun email notification?
											</span>{' '}
											is set to{' '}
											<span className="italic">Yes</span>,
											this message will be included in the
											email to the client.
										</span>
									}
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								type="button"
								text="Submit"
								tier="tertiary"
								Icon="CheckIcon"
								disabled={disabled}
								onClick={handleSubmit}
							/>
						</FormFooter>
					</Form>
				</Fragment>
			)}
		</Modal>
	);
}
