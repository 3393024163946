import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useId,
	useState,
	Dispatch,
	Fragment,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Table,
	Button,
	DataCell,
	useAxios,
	TableRow,
	TableBody,
	TableHead,
	Container,
	EmptyTable,
	HeaderCell,
	LoadingBox,
	FormFooter,
	TextButton,
	ContentWrapper,
	ContentSection,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Batch,
	ClinicData,
	formatDate,
	Pagination,
	capitalize,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

const projectedFields = [
	'type',
	'sample.batch._id',
	'_id',
	'sample.batch.name',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.pangea_id',
	'sample.specimen_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'requisition_form.sample_collection_date',
	'sample.sample_collection_date',
	'sample.sample_received_date',
];

type Data = (ClinicData | NonClinicData) & {
	checked: boolean;
	disabled: boolean;
};

/* eslint-disable-next-line */
export interface AddSamplesToBatchProps {}

export default function AddSamplesToBatch(props: AddSamplesToBatchProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { batchId } = useParams();

	const [disabled, setDisabled] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [mainCheckbox, setMainCheckbox] = useState(false);
	const [data, setData] = useState<Data[] | undefined>(undefined);
	const [batch, setBatch] = useState<Batch | undefined>(undefined);
	const [selectedSamples, setSelectedSamples] = useState<Data[]>([]);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !user) return;
			else if (!unmounted) setData(undefined);

			try {
				const [
					{
						data: {
							data: { data: data1, total_entries },
						},
					},
					{
						data: { data: data2 },
					},
				] = await Promise.all([
					await (
						await axios
					).post(
						`/api/cls/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
						{
							query: {
								'sample.batch': null,
								'sample.assay': 'bladdercare',
								'sample.status': 'pending',
							},
							projected_fields: projectedFields,
						}
					),
					await (await axios).post(`/api/cls/batches/${batchId}`, {}),
				]);

				if (!unmounted) {
					const parsedData1 = JSON.parse(data1);
					console.log(parsedData1);

					setData(
						parsedData1.map(
							(datum: ClinicData | NonClinicData) => ({
								...datum,
								checked: false,
								disabled:
									datum.sample.batch &&
									datum.sample.batch._id.$oid === batchId,
							})
						)
					);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));

					const parsedData2 = JSON.parse(data2);
					console.log(parsedData2);

					setBatch(parsedData2);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		user,
		axios,
		batchId,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	// Checkbox functions
	const handleSelectRow = (sampleId: string) => {
		setData((prevValue) => {
			if (prevValue) {
				const newArray = prevValue.map((x_data) => {
					if (x_data._id.$oid === sampleId)
						return {
							...x_data,
							checked: !x_data.checked,
						};
					else return x_data;
				});

				return newArray;
			}

			return;
		});
	};

	const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		if (data) {
			setMainCheckbox(true);

			setData(() =>
				data.map((x_data) => {
					if (x_data.disabled) {
						return {
							...x_data,
							checked: false,
						};
					}

					return {
						...x_data,
						checked: event.target.checked,
					};
				})
			);
		}
	};

	useEffect(() => {
		if (data) {
			let count = 0,
				disabled = 0;

			for (const datum of data) {
				if (datum.checked) count += 1;
				if (datum.disabled) disabled += 1;
			}

			setMainCheckbox(
				Boolean(data.length) && count === data.length - disabled
			);
			setShowButton(Boolean(count));
		}
	}, [data]);

	const handleAddSamples = () => {
		if (!data) return;

		setSelectedSamples(data.filter((sample) => sample.checked));
	};

	const handleCreateBatch = async () => {
		if (disabled || !axios || !selectedSamples) return;
		else if (!selectedSamples.length) {
			toast.error('Select samples!');
			return;
		}

		setDisabled(true);

		toast.loading('Adding...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/cls/batches/${batchId}/add-samples`, {
				samples: selectedSamples.map((sample) => sample._id.$oid),
			});

			toast.dismiss();

			navigate(`/cls/view/batches/${batchId}`);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				heading="Add samples to a batch"
				Icon="RectangleGroupIcon"
			>
				{!data || !batch ? (
					<LoadingBox />
				) : (
					<Fragment>
						<ContentSection heading="Details">
							<DescriptionList>
								<DescriptionItem
									term="Status"
									details={capitalize(
										batch.status.replace(/_/g, ' ')
									)}
								/>
								<DescriptionItem
									term="Name"
									details={batch.name}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Date created"
									details={formatDate(
										batch.metadata.date_created.$date
									)}
								/>
								<DescriptionItem
									term="# of samples"
									details={
										batch.samples.length
											? batch.samples.length
											: '0'
									}
								/>
							</DescriptionList>
						</ContentSection>

						<ContentSectionDivider />

						<ContentSection
							heading="Available samples"
							description="All the listed samples have been accessioned. The Pangea ID will be automatically assigned when the batch is created."
						>
							<BladderCARESamplesTable
								data={data}
								batch={batch}
								mainCheckbox={mainCheckbox}
								handleSelectRow={handleSelectRow}
								handleSelectAll={handleSelectAll}
								pagination={{
									pagination,
									setPagination,
								}}
							/>
							<div className="flex items-center justify-end pt-4">
								<Button
									type="submit"
									tier="tertiary"
									Icon="PlusIcon"
									disabled={!showButton}
									text="Select sample(s)"
									onClick={handleAddSamples}
								/>
							</div>
						</ContentSection>

						<ContentSectionDivider />

						<ContentSection
							heading="Selected samples"
							headingAction={
								<div className="bg-gray-100 border border-gray-200 px-3 py-1 rounded-md">
									<p className="text-sm font-medium">
										Number of selected samples:{' '}
										{selectedSamples.length}
									</p>
								</div>
							}
						>
							<BladderCARESamplesTable
								batch={batch}
								data={selectedSamples}
								type="selected_samples"
								setData={setData}
								setSelectedSamples={setSelectedSamples}
							/>
							<FormFooter>
								<Button
									type="submit"
									tier="tertiary"
									Icon="PlusIcon"
									text="Add samples"
									onClick={handleCreateBatch}
									disabled={!(selectedSamples.length > 0)}
								/>
							</FormFooter>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}

interface BladderCARESamplesTableProps {
	data: Data[];
	batch: Batch;
	mainCheckbox?: boolean;
	type?: 'selected_samples';
	pagination?: PaginationState;
	handleSelectRow?: (sampleId: string) => void;
	handleSelectAll?: ChangeEventHandler<HTMLInputElement>;
	setData?: Dispatch<SetStateAction<Data[] | undefined>>;
	setSelectedSamples?: Dispatch<SetStateAction<Data[]>>;
}

function BladderCARESamplesTable(props: BladderCARESamplesTableProps) {
	return (
		<Table pagination={props.pagination}>
			<TableHead>
				{'mainCheckbox' in props && 'handleSelectAll' in props && (
					<HeaderCell customWidth="w-16">
						<div className="flex items-center justify-center">
							<input
								type="checkbox"
								checked={props.mainCheckbox}
								onChange={props.handleSelectAll}
								className="w-5 h-5 cursor-pointer border border-gray-300 rounded-md"
							/>
						</div>
					</HeaderCell>
				)}
				{props.type === 'selected_samples' && (
					<HeaderCell>Actions</HeaderCell>
				)}
				{/* <HeaderCell>In batches</HeaderCell> */}
				<HeaderCell>Entity</HeaderCell>
				<HeaderCell>Provider</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea id</HeaderCell>
				<HeaderCell>Specimen id</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data && props.data.length ? (
					props.data.map((datum, index) => {
						const clinicSample = datum.type === 'clinic';

						return (
							<TableRow key={index} index={index}>
								{'mainCheckbox' in props && (
									<DataCell>
										<div className="flex items-center justify-center">
											<input
												disabled={
													datum.sample.batch &&
													datum.sample.batch._id
														.$oid ===
														props.batch._id.$oid
												}
												type="checkbox"
												checked={datum.checked}
												onChange={() => {
													if (props.handleSelectRow)
														props.handleSelectRow(
															datum._id.$oid
														);
												}}
												className="w-5 h-5 cursor-pointer border border-gray-300 rounded-md disabled:bg-gray-100 disabled:cursor-not-allowed"
											/>
										</div>
									</DataCell>
								)}
								{props.type === 'selected_samples' && (
									<DataCell>
										<TextButton
											color="blue"
											text="Remove"
											type="button"
											className="group-hover:text-gray-700"
											onClick={() => {
												if (
													props.setSelectedSamples &&
													props.setData
												) {
													props.setData(
														(prevValue) => {
															if (!prevValue)
																return prevValue;

															const newArray =
																prevValue.map(
																	(
																		x_data
																	) => {
																		if (
																			x_data
																				._id
																				.$oid ===
																			datum
																				._id
																				.$oid
																		)
																			return {
																				...x_data,
																				checked:
																					false,
																			};
																		else
																			return x_data;
																	}
																);

															return newArray;
														}
													);

													props.setSelectedSamples(
														(prevValue) => {
															return prevValue.filter(
																(data) =>
																	data._id
																		.$oid !==
																	datum._id
																		.$oid
															);
														}
													);
												}
											}}
										/>
									</DataCell>
								)}
								{/* <DataCell>
									{datum.sample.batch &&
										datum.sample.batch.name}
								</DataCell> */}
								<DataCell>
									{getLabelFromValue(
										datum.type,
										ORGANIZATIONS.types
									)}
								</DataCell>
								<DataCell>
									{datum.customer.first_name.concat(
										' ',
										datum.customer.last_name
									)}
								</DataCell>
								<DataCell>{datum.organization.name}</DataCell>
								<DataCell>{datum.sample.pangea_id}</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? formatDate(
												datum.patient.date_of_birth
										  )
										: null}
								</DataCell>
								<DataCell>
									{clinicSample
										? formatDate(
												datum.requisition_form
													.sample_collection_date
										  )
										: formatDate(
												datum.sample
													.sample_collection_date
										  )}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_received_date
											? datum.sample.sample_received_date
											: undefined
									)}
								</DataCell>
							</TableRow>
						);
					})
				) : (
					<EmptyTable
						Icon="DocumentTextIcon"
						heading="No selected sample(s)"
						description="Select a sample from the table above"
					/>
				)}
			</TableBody>
		</Table>
	);
}
