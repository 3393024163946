import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	Button,
	useAxios,
	Container,
	FormFooter,
	LoadingBox,
	ContentWrapper,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import { Attribute, initialAttributeValue } from '@pangea-lis-apps/utils';

import {
	IPayLoad,
	initPayLoad,
	mapClinicFormData,
	INonClinicFormData,
} from './data-types';
import { FindDiff } from './update-data-components/utils';
import { CommentsModal } from './update-data-components/components';

import { SampleSection } from './update-data-components/sample-section';
import { TRFVersionFilter } from './update-data-components/trf-version-filter';

export default function UpdateReport() {
	const toastId = useId();
	const { dataId } = useParams();
	const axios = useAxios(toastId);

	const [data, setData] = useState<INonClinicFormData>();
	const [originalData, setOriginalData] = useState<INonClinicFormData>();
	const [payload, setPayLoad] = useState<IPayLoad>(initPayLoad);

	const [refresh, setRefresh] = useState(false);
	const [fieldRefresh, setFieldRefresh] = useState(false);
	const [commentsModalVisible, setCommentsModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	const handleSubmitForm = () => {
		const payload = FindDiff(originalData, data);

		if (!payload) {
			toast.error('No Data Change');
			return;
		}

		setPayLoad(payload);
		setCommentsModalVisible(true);
	};

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/client-services/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);

					const formdata = mapClinicFormData(parsedData);

					setOriginalData({ ...formdata });
					setData({ ...formdata });
				} else {
					console.log('Unmounted!');
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, refresh]);

	return (
		<Container>
			<ContentWrapper Icon="PencilIcon" heading="Update Data">
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<CommentsModal
							data={payload}
							attribute={attribute}
							setRefresh={setRefresh}
							heading="Update Submission"
							visible={{
								visible: commentsModalVisible,
								setVisible: setCommentsModalVisible,
							}}
						/>

						<SampleSection
							data={data}
							payload={payload}
							originalData={originalData}
							setRefresh={setFieldRefresh}
						/>

						<ContentSectionDivider />

						<TRFVersionFilter
							data={data}
							originalData={originalData}
							payload={payload}
							setRefresh={setFieldRefresh}
						/>

						<FormFooter>
							<Button
								text="Update"
								type="button"
								tier="tertiary"
								onClick={() => handleSubmitForm()}
							/>
						</FormFooter>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
