import { SetStateAction, Dispatch } from 'react';

import { TRFSection } from '../update-data-components/trf-section-general';

interface ITRFSection {
	data: any;
	payload: any;
	originalData: any;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function TRFSectionFitV1(props: ITRFSection) {
	return (
		<TRFSection
			data={props.data}
			payload={props.payload}
			originalData={props.originalData}
			setRefresh={props.setRefresh}
		/>
	);
}
