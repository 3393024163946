import {
	useState,
	Dispatch,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { Option, classNames, Query } from '@pangea-lis-apps/utils';
import {
	Modal,
	Button,
	Combobox,
	DateField,
	TextButton,
	NumberField,
	SelectField,
	GenericField,
} from '@pangea-lis-apps/ui';

export interface HeaderCellWithSearchProps {
	name: string;
	query: Query;
	label: string;
	styles?: string;
	property: string;
	options?: Option[];
	customHeaderWidth?: string;
	setQuery: Dispatch<SetStateAction<Query>>;
	type: 'text' | 'number' | 'date' | 'select' | 'combobox';
}

export function HeaderCellWithSearch(props: HeaderCellWithSearchProps) {
	const [modalVisible, setModalVisible] = useState(false);
	const [input, setInput] = useState<any>('');

	const handleInputChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setInput(
				props.type === 'number'
					? parseInt(target.value, 10)
					: target.value
			);
	};
	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (input) {
			props.setQuery((prevState: Query) => ({
				...prevState,
				[props.property]: input,
			}));

			setModalVisible(false);
		}
	};

	const handleRefresh = () => {
		props.setQuery((prevState: Query) => {
			delete prevState[props.property];

			return { ...prevState };
		});

		setModalVisible(false);
		setInput('');
	};

	return (
		<th
			scope="col"
			className={classNames(
				`p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${props.styles}`,
				props.customHeaderWidth ? props.customHeaderWidth : 'w-52'
			)}
		>
			<Modal
				visible={modalVisible}
				customWidth="max-w-md"
				onClose={() => setModalVisible(false)}
				title={`Search by ${props.name.replace(/_/g, ' ')}`}
			>
				<form
					autoComplete="off"
					onSubmit={onSubmit}
					className="space-y-6"
				>
					<div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
						{['text', 'number', 'date', 'select'].includes(
							props.type
						) && (
							<div className="sm:col-span-4">
								{props.type === 'text' && (
									<GenericField
										required
										type="text"
										value={input}
										name={props.name}
										label={props.label}
										placeholder="e.g., Search term"
										handleInputChange={handleInputChange}
									/>
								)}
								{props.type === 'number' && (
									<NumberField
										required
										value={input}
										name={props.name}
										label={props.label}
										placeholder="e.g., Enter value"
										handleInputChange={handleInputChange}
									/>
								)}
								{props.type === 'date' && (
									<DateField
										required
										value={input}
										name={props.name}
										label={props.label}
										handleInputChange={handleInputChange}
									/>
								)}
								{props.type === 'select' && props.options && (
									<SelectField
										required
										value={input}
										name={props.name}
										label={props.label}
										options={props.options}
										handleSelect={handleInputChange}
									/>
								)}
							</div>
						)}
						{props.type === 'combobox' && props.options && (
							<div className="sm:col-span-4">
								<Combobox
									required
									value={input}
									name={props.name}
									label={props.label}
									options={props.options}
									handleSelect={(value: string) =>
										setInput(value)
									}
								/>
							</div>
						)}
					</div>
					<div className="flex items-center justify-between">
						<TextButton
							color="gray"
							text="Clear"
							type="button"
							onClick={handleRefresh}
						/>
						<Button
							text="Search"
							type="submit"
							tier="tertiary"
							Icon="MagnifyingGlassIcon"
						/>
					</div>
				</form>
			</Modal>
			<div className="w-full inline-flex items-center justify-between">
				{props.label}
				<MagnifyingGlassIcon
					aria-hidden="true"
					onClick={() => setModalVisible(true)}
					className={`flex-none h-4 w-4 cursor-pointer hover:text-gray-700 ${
						props.property in props.query &&
						// props.query[props.property] &&
						'text-blue-500'
					}`}
				/>
			</div>
		</th>
	);
}
