import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {
	useId,
	Dispatch,
	useState,
	ReactNode,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
	Fragment,
} from 'react';

import {
	Form,
	Modal,
	Button,
	FormGroup,
	FormFooter,
	TextButton,
	TextareaField,
	GenericField,
} from '@pangea-lis-apps/ui';

export interface PublicPageWrapperProps {
	heading: string;
	subheading?: string;
	children: ReactNode;
	description: string;
}

export function PublicPageWrapper(props: PublicPageWrapperProps) {
	const [visible, setVisible] = useState(false);

	return (
		<Fragment>
			<SendSupportMessageModal visible={{ visible, setVisible }} />
			<div className="bg-white h-screen flex flex-col lg:relative">
				<div className="flex-grow flex flex-col">
					<main className="flex-grow flex flex-col bg-white">
						<div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
							<div className="flex-shrink-0 pt-10 sm:pt-16">
								<Link to="/" className="inline-flex">
									<span className="sr-only">Logo</span>
									<img
										alt="Logo"
										className="h-16 w-auto"
										src="https://bentley-internal-test-submission-system-public.s3.amazonaws.com/pangea-lab-logo.png"
									/>
								</Link>
							</div>
							<div className="flex-shrink-0 my-auto py-16 sm:py-32">
								{props.subheading && (
									<p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">
										{props.subheading}
									</p>
								)}
								<h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
									{props.heading}
								</h1>
								<p className="mt-4 text-base text-gray-500 max-w-md">
									{props.description}
								</p>
								<div className="mt-6">{props.children}</div>
							</div>
						</div>
					</main>
					<footer className="flex-shrink-0 bg-gray-50">
						<div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
							<nav className="flex space-x-4">
								<TextButton
									color="gray"
									type="button"
									text="Contact Support"
									onClick={() => setVisible(true)}
								/>
							</nav>
						</div>
					</footer>
				</div>
				<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
					<img
						alt="Background"
						src="/assets/auth-bg.jpg"
						className="absolute inset-0 h-full w-full object-cover"
					/>
				</div>
			</div>
		</Fragment>
	);
}

const initialFormValues = {
	first_name: '',
	last_name: '',
	email: '',
	subject: '',
	message:
		"Please provide the following details for the issue you're encountering:\n\nWhat page were you on?\nWhat action were you trying to perform?\nDid you receive an error notification? If so, what did it say?",
};

interface SendSupportMessageModalProps {
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

const SendSupportMessageModal = (props: SendSupportMessageModalProps) => {
	const toastId = useId();
	const toastOptions = { id: toastId };

	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState(initialFormValues);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Sending...', toastOptions);

		try {
			await axios.post(`/api/shared/contact-support`, {
				form_values: formValues,
			});

			toast.success(
				"You'll receive a confirmation email shortly. If not, please try sending another email.",
				toastOptions
			);

			handleClose();
		} catch (error) {
			console.log(error);

			toast.error(
				'Message failed to send! Please try again!',
				toastOptions
			);
			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLTextAreaElement | HTMLInputElement;

		if (target && target.name)
			setFormValues((prevValues) => ({
				...prevValues,
				[target.name]: target.value,
			}));
	};

	const handleClose = () => {
		setDisabled(false);
		setVisible(false);
		setFormValues(initialFormValues);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-xl"
			title="Contact Support"
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup>
					<div className="sm:col-span-3">
						<GenericField
							required
							type="text"
							name="first_name"
							label="First Name"
							placeholder="e.g., Arthur"
							handleInputChange={handleChange}
							value={formValues.first_name}
						/>
					</div>
					<div className="sm:col-span-3">
						<GenericField
							required
							type="text"
							name="last_name"
							label="Last Name"
							placeholder="e.g., Kim"
							handleInputChange={handleChange}
							value={formValues.last_name}
						/>
					</div>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="email"
							name="email"
							label="Email"
							placeholder="e.g., arthurkim@gmail.com"
							handleInputChange={handleChange}
							value={formValues.email}
						/>
					</div>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="text"
							name="subject"
							label="Subject"
							placeholder="The application logged me out"
							handleInputChange={handleChange}
							value={formValues.subject}
						/>
					</div>
					<div className="sm:col-span-6">
						<TextareaField
							required
							rows={10}
							name="message"
							label="Message"
							value={formValues.message}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Send"
						type="submit"
						tier="tertiary"
						Icon="PaperAirplaneIcon"
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
};

export default PublicPageWrapper;
