import toast from 'react-hot-toast';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	Date,
	formatDate,
	ClinicData,
	NonClinicData,
	ActionMetadata,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';
import {
	Table,
	useAxios,
	TableRow,
	DataCell,
	TableHead,
	TableBody,
	TextButton,
	EmptyTable,
	HeaderCell,
	TableLoading,
	DescriptionItem,
} from '@pangea-lis-apps/ui';

interface ReportVersion {
	aws_object_key: string;
	version_id: string;
	verified: {
		value: boolean;
		metadata: ActionMetadata;
	};
	note: {
		value: string;
		metadata: ActionMetadata;
	};
	date_generated: Date;
}

interface ReportVersionProps {
	data: ClinicData | NonClinicData;
}

export function ReportVersions(props: ReportVersionProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const role = getRoleFromPathname();
	const [reports, setReports] = useState<ReportVersion[] | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !role || !props.data) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(
					`/api/${role}/reports/all?data_id=${props.data._id.$oid}`
				);

				if (!unmounted) setReports(JSON.parse(data));
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, role, props.data]);

	const handleDownloadAttachment = async (
		aws_object_key: string,
		versionId: string
	) => {
		if (!axios) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).get(
				`/api/${role}/report?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}&version_id=${versionId}`
			);

			toast.dismiss();

			window.open(data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<DescriptionItem term="Report Versions" customColSpan="sm:col-span-3">
			<Table className="mt-3">
				<TableHead>
					<HeaderCell>Version</HeaderCell>
					<HeaderCell>Verified?</HeaderCell>
					<HeaderCell>Report</HeaderCell>
					<HeaderCell>Notes</HeaderCell>
					<HeaderCell>Date Generated</HeaderCell>
				</TableHead>
				<TableBody>
					{reports ? (
						reports.length > 0 ? (
							reports.map(
								(report: ReportVersion, index: number) => {
									const key = report.aws_object_key
										.split('/')
										.pop();

									return (
										<TableRow key={index} index={index}>
											<DataCell>
												{reports.length - index}
											</DataCell>
											<DataCell>
												{report.verified.value
													? `Verified by ${report.verified.metadata.by.first_name.concat(
															' ',
															report.verified
																.metadata.by
																.last_name
													  )} on ${formatDate(
															report.verified
																.metadata.date
																.$date
													  )}`
													: null}
											</DataCell>
											<DataCell>
												<TextButton
													color="blue"
													type="button"
													text="Download"
													onClick={() =>
														handleDownloadAttachment(
															report.aws_object_key,
															report.version_id
														)
													}
												/>
											</DataCell>
											<DataCell>
												{report.note.value && (
													<Fragment>
														{report.note.value} By{' '}
														{report.note.metadata.by.first_name.concat(
															' ',
															report.note.metadata
																.by.last_name
														)}{' '}
														on{' '}
														{formatDate(
															report.note.metadata
																.date.$date
														)}
													</Fragment>
												)}
											</DataCell>
											<DataCell>
												{formatDate(
													report.date_generated.$date
												)}
											</DataCell>
										</TableRow>
									);
								}
							)
						) : (
							<EmptyTable
								Icon="ListBulletIcon"
								heading="No versions"
							/>
						)
					) : (
						<TableLoading />
					)}
				</TableBody>
			</Table>
		</DescriptionItem>
	);
}
