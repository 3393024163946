import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
	useId,
	useState,
	useEffect,
	ChangeEvent,
	MouseEventHandler,
} from 'react';

import {
	Table,
	Button,
	useAxios,
	TableRow,
	Combobox,
	DataCell,
	Container,
	FormGroup,
	TableHead,
	TableBody,
	DateField,
	EmptyTable,
	HeaderCell,
	FormFooter,
	SelectField,
	TableLoading,
	GenericField,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	SearchFiltersForm,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	SHARED,
	DateRange,
	ClinicData,
	Pagination,
	formatDate,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getSpecimenTypes,
	getSampleStatuses,
	getLabelFromValue,
	TableSearchFunctions,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import { useCustomers, useOrganizations } from '../../../../utils/hooks';

const projectedFields = [
	'type',
	'sample.status',
	'sample.ruo',
	'sample.assay',
	'sample.priority',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.pangea_id',
	'_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_specimen_type',
	'sample.sample_received_date',
	'sample.sample_collection_date',
	'sample.accessioning_approval.approve.value',
	'sample.accessioning_approval.approve.metadata.date',
];

interface FormValues {
	'customer._id'?: string;
	'organization._id'?: string;
	'sample.status'?: string;
	'sample.ruo'?: string;
	'sample.assay'?: string;
	'sample.priority'?: string;
	'sample.pangea_id'?: string;
	'sample.specimen_id'?: string;
	'sample.collection_kit_id'?: string;
	'sample.sample_specimen_type'?: string;
	'sample.sample_received_date'?: string | DateRange;
	[key: string]: string | undefined | DateRange;
	'patient.first_name'?: string;
	'patient.last_name'?: string;
	'patient.date_of_birth'?: string;
	'sample.sample_collection_date'?: string | DateRange;
	'sample.misc.added_fields.first_name'?: string;
	'sample.misc.added_fields.last_name'?: string;
	'sample.misc.added_fields.date_of_birth'?: string;
}

const initialFormValues = {
	'customer._id': '',
	'organization._id': '',
	'sample.status': '',
	'sample.ruo': '',
	'sample.assay': '',
	'sample.priority': '',
	'sample.pangea_id': '',
	'sample.specimen_id': '',
	'sample.collection_kit_id': '',
	'sample.sample_specimen_type': '',
	'sample.sample_received_date': '',
	'sample.sample_collection_date': '',
	'patient.first_name': '',
	'patient.last_name': '',
	'patient.date_of_birth': '',
	'sample.misc.added_fields.first_name': '',
	'sample.misc.added_fields.last_name': '',
	'sample.misc.added_fields.date_of_birth': '',
};

const initialDateRangesDisplayValues = {
	'sample.sample_received_date': false,
	'sample.sample_collection_date': false,
};

const dateRangeProperties = [
	'sample.sample_received_date',
	'sample.sample_collection_date',
];

/* eslint-disable-next-line */
export interface ViewSamplesProps {}

export default function ViewSamples(props: ViewSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/client-services/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{ query, projected_fields: projectedFields }
				);

				if (!unmounted) {
					const samples = JSON.parse(data);
					console.log(samples);

					setData(samples);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	// For search filters
	const customers = useCustomers();
	const organizations = useOrganizations();
	const [dateRangesDisplay, setDateRangesDisplay] = useState<{
		[key: string]: boolean;
	}>(initialDateRangesDisplayValues);

	const handleChange = (event: ChangeEvent) =>
		TableSearchFunctions.handleChange(
			event,
			setFormValues,
			dateRangeProperties,
			dateRangesDisplay
		);

	const handleChangeDateDisplay = (propName: string) =>
		TableSearchFunctions.handleChangeDateDisplay(
			propName,
			setDateRangesDisplay,
			setFormValues
		);

	const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (
		event
	) => {
		event.preventDefault();

		if (!TableSearchFunctions.hasAtLeastOneNonNullValue(formValues)) {
			toast.error('Provide a value for at least one filter criterion.');
			return;
		}

		const normalizedFormValues =
			TableSearchFunctions.normalizeFormValues(formValues);

		setQuery(normalizedFormValues);
		setPagination((prevValue) => ({
			...prevValue,
			page_number: 1,
		}));
	};

	const handleCustomerSelect = (customerId: string) => {
		if (customers) {
			const customer = customers.filter(
				(customer) => customer.value === customerId
			)[0];

			if (customer)
				setFormValues((prevValue) => ({
					...prevValue,
					'customer._id': customer.value,
				}));
		}
	};

	const handleOrganizationSelect = (organizationId: string) => {
		if (organizations) {
			const organization = organizations.filter(
				(organization) => organization.value === organizationId
			)[0];

			if (organization)
				setFormValues((prevValue) => ({
					...prevValue,
					'organization._id': organization.value,
				}));
		}
	};

	// Download results as Excel
	const [downloadDisabled, setDownloadDisabled] = useState(false);

	const handleDownload: MouseEventHandler = async (event) => {
		event.preventDefault();

		if (downloadDisabled || !axios) return;

		let query = {};

		if (TableSearchFunctions.hasAtLeastOneNonNullValue(formValues))
			query = TableSearchFunctions.normalizeFormValues(formValues);

		setDownloadDisabled(true);

		toast.loading('Downloading...', toastOptions);

		try {
			const { data } = await (
				await axios
			).post(
				'/api/client-services/search/data/download',
				{
					query,
				},
				{
					responseType: 'arraybuffer',
				}
			);

			toast.dismiss();

			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Specimen Reports List ${new Date().toLocaleString('en-US', {
					month: '2-digit',
					day: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					hour12: true,
				})}.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			setDownloadDisabled(false);
		} catch (error) {
			console.log(error);
			setDownloadDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper Icon="FingerPrintIcon" heading="All Samples">
				<SearchFiltersForm
					disabled={disabled}
					handleSubmit={handleSubmit}
					clearFilters={() => {
						setQuery({});
						setFormValues(initialFormValues);
						setPagination(initialPaginationValues);
						setDateRangesDisplay(initialDateRangesDisplayValues);
					}}
				>
					<FormGroup>
						<div className="sm:col-span-2">
							<SelectField
								name="sample.status"
								label="Status"
								handleSelect={handleChange}
								value={formValues['sample.status'] || ''}
								options={[
									{
										label: 'Select an Option',
										value: '',
									},
									...getSampleStatuses(),
								]}
							/>
						</div>
						<div className="sm:col-span-4"></div>
						<div className="sm:col-span-2">
							<Combobox
								label="Customer"
								name="customer._id"
								handleSelect={handleCustomerSelect}
								value={formValues['customer._id'] || ''}
								options={customers || SHARED.no_options}
							/>
						</div>
						<div className="sm:col-span-2">
							<Combobox
								label="Organization"
								name="organization._id"
								handleSelect={handleOrganizationSelect}
								value={formValues['organization._id'] || ''}
								options={organizations || SHARED.no_options}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Pangea ID"
								name="sample.pangea_id"
								placeholder="e.g., XZ12345678"
								handleInputChange={handleChange}
								value={formValues['sample.pangea_id'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Specimen ID"
								name="sample.specimen_id"
								placeholder="e.g., XZ12345678"
								handleInputChange={handleChange}
								value={formValues['sample.specimen_id'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Collection Kit ID"
								name="sample.collection_kit_id"
								placeholder="e.g., XZ12345678"
								handleInputChange={handleChange}
								value={
									formValues['sample.collection_kit_id'] || ''
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="Assay"
								name="sample.assay"
								handleSelect={handleChange}
								value={formValues['sample.assay'] || ''}
								options={[
									{
										value: '',
										label: 'Select an Option',
									},
									...ASSAYS['assays'],
								]}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Specimen Type"
								placeholder="e.g., Urine"
								name="sample.sample_specimen_type"
								handleInputChange={handleChange}
								value={
									formValues['sample.sample_specimen_type'] ||
									''
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<SelectField
								label="Priority Level"
								name="sample.priority"
								handleSelect={handleChange}
								options={ORGANIZATIONS.priorityLevels}
								value={formValues['sample.priority'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="RUO"
								name="sample.ruo"
								handleSelect={handleChange}
								options={FORMS.yes_no_options}
								value={formValues['sample.ruo'] || ''}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<DateField
								label="Collection Date"
								range={
									dateRangesDisplay[
										'sample.sample_collection_date'
									]
								}
								handleChangeDateDisplay={
									handleChangeDateDisplay
								}
								name="sample.sample_collection_date"
								handleInputChange={handleChange}
								value={
									formValues[
										'sample.sample_collection_date'
									] || ''
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Received Date"
								range={
									dateRangesDisplay[
										'sample.sample_received_date'
									]
								}
								handleChangeDateDisplay={
									handleChangeDateDisplay
								}
								name="sample.sample_received_date"
								handleInputChange={handleChange}
								value={
									formValues['sample.sample_received_date'] ||
									''
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Patient First Name"
								name="patient.first_name"
								placeholder="e.g., Arthur"
								handleInputChange={handleChange}
								value={formValues['patient.first_name'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Patient Last Name"
								name="patient.last_name"
								placeholder="e.g., Manning"
								handleInputChange={handleChange}
								value={formValues['patient.last_name'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Patient Date of Birth"
								name="patient.date_of_birth"
								handleInputChange={handleChange}
								value={
									formValues['patient.date_of_birth'] || ''
								}
							/>
						</div>
					</FormGroup>
					<Disclosure>
						{({ open }) => (
							<div>
								<Disclosure.Button
									className={`${
										open
											? 'rounded-t-md border-l border-r border-t'
											: 'rounded-md border'
									} bg-gray-100 w-full text-left text-sm border-gray-200 px-4 py-2 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75`}
								>
									<div className="flex justify-between w-full">
										<p className="text-gray-900 font-medium">
											Additional searchable fields for
											non-clinic samples
										</p>
										<ChevronUpIcon
											className={`${
												open
													? 'rotate-180 transform'
													: ''
											} h-5 w-5 text-gray-500`}
										/>
									</div>
									<p className="mt-1 text-xs">
										These are data points provided by
										organizations that aren't required for
										accessioning or processing the sample.
									</p>
								</Disclosure.Button>
								<Disclosure.Panel>
									<ContentSection className="border rounded-b-lg border-gray-200 shadow-sm bg-gray-100 py-4 px-4 sm:!px-4 lg:!px-4">
										<FormGroup>
											<div className="sm:col-span-2">
												<GenericField
													type="text"
													label="First Name"
													name="sample.misc.added_fields.first_name"
													placeholder="e.g., Arthur"
													handleInputChange={
														handleChange
													}
													value={
														formValues[
															'sample.misc.added_fields.first_name'
														] || ''
													}
												/>
											</div>
											<div className="sm:col-span-2">
												<GenericField
													type="text"
													label="Last Name"
													name="sample.misc.added_fields.last_name"
													placeholder="e.g., Manning"
													handleInputChange={
														handleChange
													}
													value={
														formValues[
															'sample.misc.added_fields.last_name'
														] || ''
													}
												/>
											</div>
											<div className="sm:col-span-2">
												<DateField
													label="Date of Birth"
													name="sample.misc.added_fields.date_of_birth"
													handleInputChange={
														handleChange
													}
													value={
														formValues[
															'sample.misc.added_fields.date_of_birth'
														] || ''
													}
												/>
											</div>
										</FormGroup>
									</ContentSection>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>
				</SearchFiltersForm>

				<ContentSection
					heading="All samples"
					description="The listed patient information is what was provided on the requisition form."
				>
					<AllSamplesTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>

					<FormFooter>
						<Button
							type="submit"
							tier="tertiary"
							text="Download"
							onClick={handleDownload}
							Icon="ArrowDownCircleIcon"
							disabled={
								downloadDisabled || (data && data.length === 0)
							}
						/>
					</FormFooter>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface AllSamplesTableProps {
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

function AllSamplesTable(props: AllSamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>Specimen Type</HeaderCell>
				{/* <HeaderCell>Entity</HeaderCell> */}
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Approved Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{getLabelFromValue(
										datum.sample.status,
										getSampleStatuses()
									)}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.assay,
										ASSAYS['assays']
									)}
								</DataCell>
								<DataCell>
									{datum.sample.ruo ? 'Yes' : 'No'}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.priority,
										ORGANIZATIONS.priorityLevels
									)}
								</DataCell>
								<DataCell>
									{datum.sample.sample_specimen_type
										? getLabelFromValue(
												datum.sample
													.sample_specimen_type,
												getSpecimenTypes()
										  )
										: null}
								</DataCell>
								{/* <DataCell>
									{getLabelFromValue(
										datum.type,
										ORGANIZATIONS.types
									)}
								</DataCell> */}
								<DataCell>
									{datum.customer.first_name.concat(
										' ',
										datum.customer.last_name
									)}
								</DataCell>
								<DataCell>{datum.organization.name}</DataCell>
								<DataCell>
									<ArrowLinkButton
										text={datum.sample.pangea_id}
										onClick={() =>
											navigate(
												`/client-services/view/samples/${datum._id.$oid}`
											)
										}
									/>
								</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.sample.collection_kit_id}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? formatDate(
												datum.patient.date_of_birth
										  )
										: null}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_collection_date
									)}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_received_date
											? datum.sample.sample_received_date
											: undefined
									)}
								</DataCell>
								<DataCell>
									{datum.sample.accessioning_approval.approve
										.value
										? formatDate(
												datum.sample
													.accessioning_approval
													.approve.metadata.date
													? datum.sample
															.accessioning_approval
															.approve.metadata
															.date.$date
													: undefined
										  )
										: null}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							heading="No samples"
							Icon="FingerPrintIcon"
							description="Start by creating a sample"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
