import { useState, Fragment, SetStateAction, Dispatch } from 'react';

import {
	ContentSection,
	DescriptionList,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	Attribute,
	formatDate,
	getLabelFromValue,
	initialAttributeValue,
} from '@pangea-lis-apps/utils';

import {
	UpdateNonResultModal,
	ClientServiceDescriptionItem,
} from './components';

interface ITRFSection {
	data: any;
	payload: any;
	originalData: any;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function TRFSection(props: ITRFSection) {
	const [modalVisible, setModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'select' | 'date' | 'multiple_select' | 'file' | 'email',
		selectOptions?: Option[],
		selectLabel?: string
	) => {
		setAttribute({
			property,
			value,
			type,
			label,
			selectOptions,
			selectLabel,
		});

		setModalVisible(true);
	};

	return (
		<Fragment>
			<UpdateNonResultModal
				data={props.data}
				attribute={attribute}
				setRefresh={props.setRefresh}
				heading="Edit info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<ContentSection heading="Patient Info">
				<DescriptionList>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="First Name"
						details={props.data.patient_first_name}
						originalDetails={props.originalData?.patient_first_name}
						handleClick={() =>
							handleClick(
								'patient_first_name',
								props.data.patient_first_name,
								'Patient First Name',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Middle Name"
						details={props.data.patient_middle_name}
						originalDetails={
							props.originalData?.patient_middle_name
						}
						handleClick={() =>
							handleClick(
								'patient_middle_name',
								props.data.patient_middle_name,
								'Patient Middle Name',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Last Name"
						details={props.data.patient_last_name}
						originalDetails={props.originalData?.patient_last_name}
						handleClick={() =>
							handleClick(
								'patient_last_name',
								props.data.patient_last_name,
								'Patient Last Name',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Date of Birth"
						details={formatDate(props.data.patient_date_of_birth)}
						originalDetails={formatDate(
							props.originalData?.patient_date_of_birth
						)}
						handleClick={() =>
							handleClick(
								'patient_date_of_birth',
								formatDate(props.data.patient_date_of_birth) ||
									'-',
								'Patient Date of Birth',
								'date'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Sex"
						details={getLabelFromValue(
							props.data.patient_sex,
							FORMS.sex_options
						)}
						originalDetails={getLabelFromValue(
							props.originalData?.patient_sex,
							FORMS.sex_options
						)}
						handleClick={() =>
							handleClick(
								'patient_sex',
								props.data.patient_sex,
								'Patient Sex',
								'select',
								FORMS.sex_options
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Medical Record Number (MRN)"
						details={props.data.patient_medical_record_number}
						originalDetails={
							props.originalData?.patient_medical_record_number
						}
						handleClick={() =>
							handleClick(
								'patient_medical_record_number',
								props.data.patient_medical_record_number,
								'Patient Medical Record Number (MRN)',
								'text'
							)
						}
					/>
				</DescriptionList>
			</ContentSection>

			<ContentSectionDivider />

			<ContentSection heading="Provider Info">
				<DescriptionList>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="First Name"
						details={props.data.provider_first_name}
						originalDetails={
							props.originalData?.provider_first_name
						}
						handleClick={() =>
							handleClick(
								'provider_first_name',
								props.data.provider_first_name,
								'Provider First Name',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Last Name"
						details={props.data.provider_last_name}
						originalDetails={props.originalData?.provider_last_name}
						handleClick={() =>
							handleClick(
								'provider_last_name',
								props.data.provider_last_name,
								'Provider Last Name',
								'text'
							)
						}
					/>
					<div className="sm:col-span-1"></div>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Address"
						details={props.data.organization_address_address}
						originalDetails={
							props.originalData?.organization_address_address
						}
						handleClick={() =>
							handleClick(
								'organization_address_address',
								props.data.organization_address_address ?? '',
								'Organization Address - Address',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="City"
						details={props.data.organization_address_city}
						originalDetails={
							props.originalData?.organization_address_city
						}
						handleClick={() =>
							handleClick(
								'organization_address_city',
								props.data.organization_address_city,
								'Organization Address - City',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="State"
						details={props.data.organization_address_state}
						originalDetails={
							props.originalData?.organization_address_state
						}
						handleClick={() =>
							handleClick(
								'organization_address_state',
								props.data.organization_address_state,
								'Organization Address - State',
								'select',
								FORMS.states
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="ZIP Code"
						details={props.data.organization_address_zip_code}
						originalDetails={
							props.originalData?.organization_address_zip_code
						}
						handleClick={() =>
							handleClick(
								'organization_address_zip_code',
								props.data.organization_address_zip_code,
								'Organization Address - ZIP Code',
								'text'
							)
						}
					/>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Country"
						details={props.data.organization_address_country}
						originalDetails={
							props.originalData?.organization_address_country
						}
						handleClick={() =>
							handleClick(
								'organization_address_country',
								props.data.organization_address_country,
								'Organization Address - Country',
								'select',
								FORMS.countries
							)
						}
					/>
					<div className="sm:col-span-1"></div>
					<ClientServiceDescriptionItem
						withFunction
						Icon="PencilIcon"
						term="Phone Number"
						details={props.data.organization_phone_number}
						originalDetails={
							props.originalData?.organization_phone_number
						}
						handleClick={() =>
							handleClick(
								'organization_phone_number',
								props.data.organization_phone_number,
								'Organization Phone Number',
								'text'
							)
						}
					/>
				</DescriptionList>
			</ContentSection>
		</Fragment>
	);
}
