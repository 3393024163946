import { Dispatch, Fragment, SetStateAction } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
	ASSAYS,
	ClinicData,
	NonClinicData,
	formatDate,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';
import {
	Modal,
	Alert,
	Button,
	LoadingBox,
	FormFooter,
	DescriptionItem,
	DescriptionList,
} from '@pangea-lis-apps/ui';

type NonClinicBarActions = 'flag' | 'needs_review';
type ClinicBarActions = 'flag' | 'hold' | 'needs_review';

interface RemoveSampleFromRackModalProps {
	handleRedirect?: () => void;
	data: ClinicData | NonClinicData;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export default function RemoveSampleFromRackModal(
	props: RemoveSampleFromRackModalProps
) {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const { visible, setVisible } = props.visible;

	// The name of rack type is different from the rack property name.
	const rackType = ['needs_review', 'reviewed'].includes(
		props.data.sample.status
	)
		? 'review'
		: props.data.sample.status;

	const rackPropertyName = ['needs_review', 'reviewed'].includes(
		props.data.sample.status
	)
		? 'needs_review'
		: props.data.sample.status;

	return visible ? (
		<Modal
			visible={visible}
			customWidth="max-w-md"
			hideCloseButton={true}
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onClose={() => {}}
			title="Notice to remove sample"
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<div className="space-y-6">
					<Alert
						type="warning"
						heading="Attention needed"
						description={
							<span>
								Please remove the sample from the {rackType}{' '}
								rack.
							</span>
						}
					/>

					<DescriptionList>
						{/* <DescriptionItem
							customColSpan="sm:col-span-3"
							term={`${capitalize(rackType)} reason`}
							details={
								props.data.type === 'clinic'
									? props.data.sample.accessioning_approval[
											rackPropertyName as ClinicBarActions
									  ].reason.value
									: props.data.sample.accessioning_approval[
											rackPropertyName as NonClinicBarActions
									  ].reason.value
							}
						/> */}
						<DescriptionItem
							term="Assay"
							details={getLabelFromValue(
								props.data.sample.assay,
								ASSAYS['assays']
							)}
							customColSpan="sm:col-span-3"
						/>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Specimen ID"
							details={props.data.sample.specimen_id}
						/>
						<DescriptionItem
							term="Collection Kit ID"
							details={props.data.sample.specimen_id}
						/>
						<DescriptionItem
							term="Rack name"
							details={
								props.data.type === 'clinic'
									? props.data.sample.accessioning_approval[
											rackPropertyName as ClinicBarActions
									  ].selected_well.rack_name
									: props.data.sample.accessioning_approval[
											rackPropertyName as NonClinicBarActions
									  ].selected_well.rack_name
							}
						/>
						<DescriptionItem
							term="Well location"
							details={
								props.data.type === 'clinic'
									? props.data.sample.accessioning_approval[
											rackPropertyName as ClinicBarActions
									  ].selected_well.location
									: props.data.sample.accessioning_approval[
											rackPropertyName as NonClinicBarActions
									  ].selected_well.location
							}
						/>
						<div className="sm:col-span-1"></div>
						{props.data.type === 'clinic' && (
							<Fragment>
								<DescriptionItem
									term="Patient first name"
									details={
										props.data.patient
											? props.data.requisition_form
													.patient_first_name
											: null
									}
								/>
								<DescriptionItem
									term="Patient last name"
									details={
										props.data.patient
											? props.data.requisition_form
													.patient_last_name
											: null
									}
								/>
								<DescriptionItem
									term="Patient date of birth"
									details={
										props.data.patient
											? formatDate(
													props.data.requisition_form
														.patient_date_of_birth
											  )
											: null
									}
								/>
							</Fragment>
						)}
						<DescriptionItem
							term="Provider"
							details={props.data.customer.first_name.concat(
								' ',
								props.data.customer.last_name
							)}
						/>
						<DescriptionItem
							term="Organization"
							details={props.data.organization.name}
						/>
					</DescriptionList>

					<FormFooter>
						<Button
							text="Ok"
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							onClick={() => {
								setVisible((value) => !value);

								if (props.handleRedirect) {
									props.handleRedirect();
									return;
								}

								navigate(
									redirectPath
										? redirectPath
										: `/accessioner/accession/sessions/${props.data.accessioning_session._id.$oid}/preprocess`
								);
							}}
						/>
					</FormFooter>
				</div>
			)}
		</Modal>
	) : null;
}
